* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

#root {
    height: 100%;
}

body {
    margin: 0;
    font-family: "OpenSans", "sans-serif";
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    height: 100%;
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(./fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf) format('opentype');
}


a {
    color: white;
    text-decoration: none;
}

.main-menu {
    width: 100%;
    height: 60px;
    background: #262626;
    color: #FFFFFF;
    font-size: 18px;
}

.main-menu-inner {
    width: 1100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.main-menu-inner > div {
    width: 50%;
}

@media screen and (max-width: 1000px) {
    .main-menu-inner {
        max-width: 100%;
    }
}

.logo {
    font-size: 18px;
    cursor: pointer;
}

.main-menu-list {
    display: flex;
    color: #979797;
}

.main-menu-list > div {
    cursor: pointer;
    margin-right: 50px;
}

.main-menu-list > div:hover {
    color: #FFFFFF;
}

.header {
    background: url("./images/header.png") no-repeat;
    background-size: cover;
    width: 100%;
    height: 544px;
    display: flex;
}


.header-container {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-container > div:nth-child(1) {
    width: 50%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.header-container > div:nth-child(2) {
    width: 50%;
    height: 100%;
}

.header-btc {
    width: 500px;
    height: 503px;
    background: url("./images/header-btc.png");
    background-size: contain;
    border-radius: 0;
    margin: 0 auto;
    margin-top: 100px;
}

.header-left-container {
    width: 550px;
}

.header-description1 {
    color: #FFFFFF;
    font-size: 54px;
    font-weight: 300;
    line-height: 73px;
}

.header-description2 {
    color: #E7E7E7;
    font-size: 18px;
    line-height: 24px;
    margin-top: 20px;
}

.header-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    width: 390px;
}


@media screen and (max-width: 1000px) {
    .header {
        display: block;
        height: 600px;
    }
    .header-container {
        padding: 10px;
        max-width: 100%;
        box-sizing: border-box;
    }
    .header-description1 {
        font-size: 40px;
        line-height: 53px;
        text-align: center;
    }
    .header-description2 {
        text-align: center;
    }
    .header-left-container {
        max-width: 100% !important;
    }
    .header-container > div:nth-child(1) {
        width: 100%;
    }
    .header-btc {
        max-width: 100%;
        width: 300px;
        height: 303px;
        background: url("./images/header-btc.png") no-repeat;
        background-size: contain;
        border-radius: 0;
        margin: 0 auto;
        margin-top: -50px;
    }
    .header-buttons {
        display: block;
        width: 100%;
        text-align: center;
    }
}


.content-block1 {
    color: #262626;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 180px;
    width: 1100px;
}

.content-block1 > div {
    width: 48%;
}

.content-block1-button {
    margin-top: 30px;
}

@media screen and (max-width: 1000px) {
    .content-block1 {
        max-width: 98%;
        display: block;
        text-align: center;
    }
    .content-block1 > div {
        width: 100%;
    }
}

.caption {
    font-size: 54px;
    line-height: 63px;
    font-weight: 700;
    margin-bottom: 20px;
}

.description {
    color: #979797;
    font-size: 18px;
    line-height: 24px;
}

.features {

}

.features > div {
    display: flex;
}

.features > div:nth-child(1) {
    margin-bottom: 65px;
}

.features-item {
    width: 50%;
}

.features-item > div:nth-child(1) {
    display: flex;
    align-items: center;
}

.features-item > div > img {
    width: 38px;
    height: 42px;
}

.features-caption {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
    margin-left: 23px;
    color: #262626;
}

.features-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 60px;
    color: #979797;
}

.features-item-with-margin {
    margin-left: 30px;
}


@media screen and (max-width: 1000px) {
    .features {
        width: 99%;
        margin-top: 150px;
    }
    .features > div {
        display: block;
    }
    .features-item {
        width: 100%;
        margin-bottom: 30px;
    }
    .features-item-with-margin {
        margin-left: 0;
    }
    .features-description {
        text-align: left;
    }
    .features > div:nth-child(1) {
        margin: 0;
    }
}

.content-block2 {
    width: 100%;
    height: 494px;
    background: #000000;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 82px;
}

.content-block2-container {
    width: 1100px;
}

.content-block2-container > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content-block2-container > div:nth-child(1) > div:nth-child(1) {
    width: 50%;
}

.content-block2-container > div:nth-child(1) > div:nth-child(2) {
    width: 35%;
    color: #FFFFFF !important;
}

@media screen and (max-width: 1000px) {
    .content-block2 {
        max-width: 100%;
        height: 950px;
        display: block;
        text-align: center;
        padding-top: 30px;
    }
    .content-block2-container {
        max-width: 100%;
        height: 950px;
        display: block;
    }
    .content-block2-container > div:nth-child(1) {
        display: block;
    }
    .content-block2-container > div:nth-child(1) > div:nth-child(1) {
        width: 100%;
    }
    .content-block2-container > div:nth-child(1) > div:nth-child(2) {
        width: 100%;
    }
}


.steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 53px;
}

.steps > div {
    width: 192px;
    text-align: center;
}

.steps > div > img {
    width: 64px;
    height: 64px;
    margin: 0 auto;
}

.steps > div > div {
    font-size: 24px;
    line-height: 32px;
}

.steps-arrow {
    width: 83px;
}

.steps-arrow-down {
    width: 20px;
    height: 50px;
}

@media screen and (max-width: 1000px) {
    .steps {
        max-width: 100%;
        margin: 0 auto;
        display: block;
    }
    .steps > div {
        margin: 0 auto;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}


.content-block3 {
    width: 1100px;
    height: 320px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.content-block3-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content-block3-inner > div:nth-child(1) {
    width: 65%;
}

@media screen and (max-width: 1000px) {
    .content-block3 {
        max-width: 100%;
        display: block;
        padding-top: 30px;
        padding-bottom: 30px;
        height: 400px;
    }
    .content-block3-inner > div:nth-child(1) {
        width: 100%;
    }
    .content-block3-inner > div:nth-child(2) {
        margin-top: 30px;
    }
    .content-block3-inner {
        display: block;
        text-align: center;
    }
}

.footer {
    width: 100%;
    height: 214px;
    display: flex;
    align-items: center;
    background: #0C0B0B;
}

.footer-inner {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-inner > div:nth-child(2) {
    width: 295px;
}

.footer-caption {
    color: #FFFFFF;
    font-size: 36px;
}

@media screen and (max-width: 1000px) {
    .footer {
        padding-top: 30px;
        padding-bottom: 30px;
        text-align: center;
    }
    .footer-inner {
        max-width: 99%;
        display: block;
    }
    .footer-caption {
        margin-bottom: 30px;
    }
    .footer-inner > div:nth-child(2) {
       width: 100%;
    }
}



.checkout-background {
    background: url("./images/checkout/bg.png") no-repeat center center fixed;
    min-height: 100%;
    min-width: 1024px;

    /* Set up proportionate scaling */
    width: 100%;
    height: auto;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}

@media screen and (max-width: 1024px) { /* Specific to this particular image */
    .checkout-background {
        min-width: 0;
        max-width: 100%;
        position: initial;
    }
}

.checkout-container {
    width: 1100px;
    height: 100vh;
    padding: 56px 150px;
    box-sizing: border-box;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.9);
    overflow-y: scroll;
}

.checkout-description {
    font-size: 20px;
    margin-bottom: 20px;
}

.checkout-description > a {
    color: #0A38DB;
}

.checkout-field {
    margin-top: 10px;
    margin-bottom: 10px;
}

.checkout-form {
    width: 500px;
    margin-bottom: 40px;
}
.checkout-select-fields {
    display: flex;
    justify-content: space-between;
}

.checkout-select-fields > div {
    width: 40%;
}

.payment-form {
    display: flex;
}

.payment-radio-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 64px;
}

@media screen and (max-width: 1024px) {
    .checkout-container {
        max-width: 100%;
        padding: 30px 10px;
        height: auto;
    }
    .checkout-form {
        max-width: 100%;
    }
    .checkout-select-fields {
        display: block;
    }
    .payment-form {
        display: block !important;
    }
}

.disclaimer {
    margin-top: 50px;
    font-size: 12px;
}

.checkout-buttons {
    text-align: center;
    margin-top: 50px;
}

.qrcode-container {
    text-align: center;
}

.qrcode-container > div {
    margin-top: 30px;
}

.qrcode-container > div > div{
    margin-top: 5px;
    font-weight: 600;
}






